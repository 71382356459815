import {
  AfterViewInit,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  Signal,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromVehicles from '../../store/reducers';
import {
  getDtcTooltip,
  getTooltipSchedules,
  getVehicleStats,
  loadMaintenanceSchedules,
  loadVehiclePhotos,
} from '../../store';
import { Observable, of } from 'rxjs';
import { MaintenanceSchedule, Vehicle, VehicleGroup } from '@fc-core';
import {
  delay,
  filter,
  map,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ViewBreakpoints } from '@fc-shared/constants';
import {
  getAllRawVehicles,
  getAllVehicleGroups,
  getLoadedState,
  getLoadingState,
  getProgress,
  getSelectedFilters,
  getUsedVehicleTypes,
  getVehicleFilters,
  getVehicleGroupSortBy,
  getVehicleGroupSortByList,
  getVehicleSortBy,
  getVehicleSortByList,
  loadUsedVehicleTypes,
  loadVehicleGroups,
  loadVehicles,
  loadVehicleTypes,
  resetDefaultVehicleFilters,
  resetDefaultVehicleGroupFilters,
  selectAllVehicles,
  selectRouterParam,
  selectRouterParamData,
  selectVehicleId,
  setManyVehicleFilters,
  setSearchVehicleGroupText,
  setSearchVehicleText,
  setVehicleGroupFilterId,
  setVehicleGroupSortBy,
  setVehiclesFilter,
  setVehicleSortBy,
} from '../../../store';
import {
  FilterGroup,
  FilterItem,
} from '@fc-shared/ui/vehicle-filter/vehicles-filter.component';

import { Dtc } from '../../models/dtc';
import { SortBy } from '@fc-shared/ui/sort-by/sort-by';
import { getAllHardware } from '@fc-store/actions/hardware.actions';
import { getAllHarness } from '@fc-store/actions/harness.actions';

@Component({
  selector: 'fc-vehicle-page',
  template: `
    <fc-page-container [itemSelected]="!!(selectedVehicleId$ | async)">
      <div
        class="sidenav"
        [ngClass]="{
          'vehicle-selected': selectedVehicleId$ | async,
        }"
      >
        <div class="vehicle-page-header">
          <fc-header-panel
            #headerPanel
            (searchEmitter)="onVehicleSearch($event)"
            (sortEmitter)="onSortByChange($event)"
            [sortList]="sortByList$ | async"
            [selectedSort]="sortBy$ | async"
            [title]="'Vehicles'"
            [showFilter]="pageType() === 'vehicles'"
          >
            <fc-filter-list
              [filterGroups]="vehicleTypes$ | async"
              [selectedFilters]="selectedFilters()"
              (filterSelect)="selectFilter($event)"
              (selectAllTypesEmitter)="selectFilters($event)"
              (selectAllEmitter)="selectFilters($event)"
            ></fc-filter-list>
          </fc-header-panel>
          <fc-filter-select
            *ngIf="selectedFilters()?.length && pageType() === 'vehicles'"
            [filters]="selectedFilters()"
            [borderTop]="true"
            (clearAllEmitter)="selectFilters([])"
            (deselectFilter)="selectFilter($event)"
          ></fc-filter-select>
          <div class="m-l-24 m-r-24 m-b-24">
            <fc-toggle-button-group
              [fullWidth]="true"
              data-cy="navigation-toggle"
              [value]="pageType()"
              (change)="tabChanged()"
            >
              <fc-toggle-button
                *ngFor="let link of links"
                [routerLink]="link.url"
                [value]="link.url"
              >
                {{ link.name }}
              </fc-toggle-button>
            </fc-toggle-button-group>
          </div>
        </div>
        <div class="list">
          <router-outlet name="sidebar"></router-outlet>
        </div>
      </div>
      <div class="main">
        <router-outlet></router-outlet>
        <router-outlet name="modal"></router-outlet>
        <div
          class="vehicle-empty-state not-found"
          *ngIf="
            pageType() === 'vehicles' &&
            (allVehicles$ | async)?.length === 0 &&
            (vehicleLoading$ | async) === false
          "
        >
          <fc-empty-state
            icon="assets/img/vehicle-art.svg"
            title="Add your first vehicle"
            subtitle="There are no vehicles here yet"
            buttonText="Add vehicle"
            (buttonClicked)="addVehicle()"
          ></fc-empty-state>
        </div>
      </div>
    </fc-page-container>
  `,
  styleUrls: ['./vehicle-page.component.scss'],
})
export class VehiclePageComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostBinding('style.top.px') containerTop: number;
  vehicles$: Observable<Vehicle[]>;
  selectedVehicleId$: Observable<number>;
  vehicleLoading$: Observable<boolean>;
  vehicleFilter$: Observable<FilterGroup[]>;
  vehicleGroups$: Observable<VehicleGroup[]>;
  progress$: Observable<number>;
  vehicleTypes$: Observable<FilterGroup[]>;
  tooltipDtc$: Observable<Dtc>;
  tooltipMaintenance$: Observable<MaintenanceSchedule[]>;
  selectedFilters: Signal<FilterItem[]>;
  vehiclesLoaded$: Observable<boolean>;
  links = [
    { name: 'Vehicles', url: 'vehicles' },
    { name: 'Groups', url: 'vehicle-groups' },
  ];
  sortByList$: Observable<SortBy[]>;
  sortBy$: Observable<SortBy>;
  allVehicles$: Observable<Vehicle[]>;
  pageType: Signal<'vehicles' | 'vehicle-groups'> = this.store.selectSignal(
    selectRouterParamData('type'),
  );

  constructor(
    private store: Store<fromVehicles.State>,
    private router: Router,
    private breakPoint: BreakpointObserver,
  ) {}

  ngAfterViewInit(): void {
    this.selectedVehicleId$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      startWith(this.router),
      delay(0),
      map(() => this.tabChanged()),
      withLatestFrom(this.breakPoint.observe([ViewBreakpoints.smSize])),
      switchMap(([currentId, breakPointData]) =>
        this.getDefaultRoute(currentId, breakPointData),
      ),
    );
  }

  ngOnInit() {
    const vehicleLoadParams = [
      'vin',
      'hardware',
      'harness',
      'identifier',
      'manufacturer',
      'model',
      'year',
      'photo',
      'groups',
      'type',
      'ifta',
      'power_source',
      'created',
      'status',
      'last_reading',
      'last_state',
      'driver',
      'specifications',
      'pending_maintenance_count',
      'pending_dtc_count',
      'camera',
      'mil',
      'operators',
      'non_tracker',
      'install_type',
    ];
    this.vehicleTypes$ = this.store.pipe(
      select(getUsedVehicleTypes),
      map((types) => [
        {
          name: 'Types',
          filters: types.map((type) => ({
            ...type,
            icon: type.slug,
            type: 'type',
          })),
        },
      ]),
    );
    this.selectedFilters = this.store.selectSignal(getSelectedFilters);
    this.progress$ = this.store.pipe(select(getProgress));
    this.vehiclesLoaded$ = this.store.pipe(select(getLoadedState));
    this.vehicleFilter$ = this.store.pipe(select(getVehicleFilters));
    this.vehicleLoading$ = this.store.pipe(select(getLoadingState));
    this.vehicleGroups$ = this.store.pipe(select(getAllVehicleGroups));
    this.tooltipDtc$ = this.store.pipe(select(getDtcTooltip()));
    this.tooltipMaintenance$ = this.store.pipe(select(getTooltipSchedules()));
    this.vehicles$ = this.store.pipe(select(selectAllVehicles));
    this.allVehicles$ = this.store.pipe(select(getAllRawVehicles));
    this.store.dispatch(loadVehicles({ options: vehicleLoadParams }));
    this.store.dispatch(loadUsedVehicleTypes());
    this.store.dispatch(loadVehicleTypes());
    this.store.dispatch(loadVehicleGroups());
    this.store.dispatch(getAllHardware());
    this.store.dispatch(getAllHarness());
  }

  private getVehicleInfo(vehicleId: number) {
    if (!vehicleId) return;
    this.store.dispatch(selectVehicleId({ vehicleId }));
    this.store.dispatch(loadVehiclePhotos({ vehicleId }));
    this.store.dispatch(loadMaintenanceSchedules({ vehicleId }));
    this.store.dispatch(getVehicleStats({ vehicleId }));
  }

  tabChanged(): string {
    const currentId = this.store.selectSignal(selectRouterParam('id'));
    if (this.pageType() === 'vehicles') this.getVehicleInfo(currentId());
    this.onVehiclePageChange();
    this.clearSort();
    this.containerTop = currentId() ? 0 : 60;
    return currentId();
  }

  onVehiclePageChange() {
    if (this.pageType() === 'vehicles') {
      this.sortByList$ = this.store.pipe(select(getVehicleSortByList));
      this.sortBy$ = this.store.pipe(select(getVehicleSortBy));
    } else {
      this.sortByList$ = this.store.pipe(select(getVehicleGroupSortByList));
      this.sortBy$ = this.store.pipe(select(getVehicleGroupSortBy));
    }
  }

  selectFilters(filters: FilterItem[]) {
    this.store.dispatch(setManyVehicleFilters({ filters }));
  }

  selectFilter(filterItem: FilterItem) {
    this.store.dispatch(setVehiclesFilter({ filterItem }));
  }

  onVehicleSearch(searchText: string): void {
    this.pageType() === 'vehicles'
      ? this.store.dispatch(setSearchVehicleText({ searchText }))
      : this.store.dispatch(setSearchVehicleGroupText({ searchText }));
  }

  ngOnDestroy(): void {
    this.vehicles$ = null;
    this.store.dispatch(setVehicleGroupFilterId({ vehicleGroupFilterId: -1 }));
    this.store.dispatch(setSearchVehicleText({ searchText: '' }));
    this.store.dispatch(setSearchVehicleGroupText({ searchText: '' }));
  }

  onSortByChange(sortBy: SortBy): void {
    this.pageType() === 'vehicles'
      ? this.store.dispatch(setVehicleSortBy({ sortBy }))
      : this.store.dispatch(setVehicleGroupSortBy({ sortBy }));
  }

  openAddingDialog() {
    this.pageType() === 'vehicles'
      ? this.router.navigate([
          'vehicles/main/vehicles',
          { outlets: { modal: ['add-vehicle'] } },
        ])
      : this.router.navigate([
          'vehicles/main/vehicle-groups',
          { outlets: { modal: ['add-group'] } },
        ]);
  }

  private getDefaultRoute(
    currentId: string,
    breakPointData: BreakpointState,
  ): Observable<number> {
    const routeItem: any =
      this.pageType() === 'vehicles' ? getAllRawVehicles : getAllVehicleGroups;
    if (!currentId && !breakPointData.matches) {
      return this.store.pipe(
        select(routeItem),
        map((items: Vehicle[] | VehicleGroup[]) => {
          if (!items.length) return;
          this.router.navigate([
            `vehicles/main/${this.pageType()}`,
            items[0].id,
          ]);
          return items[0].id;
        }),
      );
    } else {
      return of(+currentId);
    }
  }

  private clearSort() {
    this.pageType() === 'vehicles'
      ? this.store.dispatch(resetDefaultVehicleFilters())
      : this.store.dispatch(resetDefaultVehicleGroupFilters());
  }

  addVehicle(): void {
    this.router.navigate([
      'vehicles/main/vehicles/',
      { outlets: { modal: ['add-vehicle'] } },
    ]);
  }
}
