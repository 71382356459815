<ng-container *ngIf="render">
  <div class="header">
    <div class="h2">{{ vehicle ? 'Update' : 'New' }} vehicle</div>
    <div class="close">
      <fc-icon-button (click)="closeVehicleForm()" data-cy="vehicle-form-close">
        <fc-icon icon="icon-clear" [size]="20" />
      </fc-icon-button>
    </div>
  </div>
  <mat-dialog-content>
    <form
      (keydown.enter)="submitForm()"
      [formGroup]="vehicleForm"
      class="core-vehicle-form"
    >
      <div class="vehicle-photo-container">
        <fc-drop-area
          #dropArea
          (dropped)="onImageDropped($event)"
          [class.hidden]="photoSrc"
          icon="icon-vehicles"
        ></fc-drop-area>
        <fc-vehicle-photo
          (addNewPhoto)="dropArea.openFileBrowser()"
          (photoDeleted)="deletePhoto()"
          *ngIf="photoSrc"
          [photoSrc]="photoSrc"
        ></fc-vehicle-photo>
      </div>
      <ng-container *ngIf="!nonTracker">
        <fc-part-select-with-dropdown
          (partSelectedEmitter)="partSelected($event)"
          [error]="hardwareError"
          [selectedPart]="selectedPart"
          [update]="!!vehicle"
          class="part-selector"
        ></fc-part-select-with-dropdown>
        <fc-vehicle-part-selector
          (click)="selectDvrPart()"
          [disable]="noHardware"
          [error]="cameraError"
          [selectedPart]="selectedCamera"
          [title]="'Add DVR'"
          class="part-selector"
        ></fc-vehicle-part-selector>
      </ng-container>
      <div class="form-row">
        <mat-form-field appearance="outline">
          <mat-label>Identifier</mat-label>
          <input
            formControlName="identifier"
            matInput
            placeholder="Identifier"
          />
          <mat-error>
            <fc-form-error
              [formGroup]="vehicleForm"
              [path]="['identifier']"
            ></fc-form-error>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-row">
        <fc-single-item-dropdown
          [additionalTemplate]="typeIconTemplate"
          [itemList]="vehicleTypes"
          [searchKey]="'name'"
          formControlName="type"
          title="Vehicle type"
        >
          <fc-form-error
            [formGroup]="vehicleForm"
            [path]="['type']"
          ></fc-form-error>
        </fc-single-item-dropdown>
        <ng-template #typeIconTemplate let-type>
          <img
            #typeImage
            (error)="typeImage.style.display = 'none'"
            [src]="'/assets/icons/vehicle-types/' + type.slug + '.svg'"
            alt="type-icon"
          />
        </ng-template>
      </div>
      <div class="form-row form-inline three">
        <fc-vehicle-manufacture-autocomplete
          data-cy="vehicle-manufacture"
          formControlName="manufacturer"
        >
          <mat-error>
            <fc-form-error
              [formGroup]="vehicleForm"
              [path]="['manufacturer']"
            ></fc-form-error>
          </mat-error>
        </fc-vehicle-manufacture-autocomplete>
        <mat-form-field appearance="outline">
          <mat-label>Model</mat-label>
          <input
            data-cy="model-input"
            formControlName="model"
            matInput
            placeholder="Model"
          />
          <mat-error>
            <fc-form-error
              [formGroup]="vehicleForm"
              [path]="['model']"
            ></fc-form-error>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Year</mat-label>
          <input
            [max]="currentYear"
            data-cy="year-input"
            formControlName="year"
            matInput
            placeholder="Year"
            type="number"
          />
          <mat-error>
            <fc-form-error
              [customErrors]="{
                pattern:
                  'Please enter a valid year between 1900 and the current year',
                max: 'The year entered cannot be greater than current year',
              }"
              [formGroup]="vehicleForm"
              [path]="['year']"
            ></fc-form-error>
          </mat-error>
        </mat-form-field>
      </div>
      <ng-container *ngIf="!noHardware">
        <div
          *ngIf="!vehicle; else updateTemplate"
          class="form-inline form-row double"
        >
          <mat-form-field appearance="outline">
            <mat-label>Odometer</mat-label>
            <input
              data-cy="odometer-input"
              formControlName="realOdometer"
              matInput
              min="0"
              placeholder="Odometer"
              type="number"
            />
            <mat-error>
              <fc-form-error
                [formGroup]="vehicleForm"
                [path]="['realOdometer']"
              ></fc-form-error>
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Engine Hours</mat-label>
            <input
              data-cy="engine-hours-input"
              formControlName="realEngineHours"
              matInput
              min="0"
              placeholder="Engine Hours"
              type="number"
            />
            <mat-error>
              <fc-form-error
                [formGroup]="vehicleForm"
                [path]="['realEngineHours']"
              ></fc-form-error>
            </mat-error>
          </mat-form-field>
        </div>
      </ng-container>

      <ng-template #updateTemplate>
        <div class="form-row">
          <mat-form-field appearance="outline" class="vin-number-input">
            <mat-label>Vin number</mat-label>
            <input
              data-cy="vin-number"
              formControlName="vinOverride"
              matInput
              placeholder="Vin number"
            />
            <mat-error>
              <fc-form-error
                [formGroup]="vehicleForm"
                [path]="['vinOverride']"
              ></fc-form-error>
            </mat-error>
          </mat-form-field>
        </div>
      </ng-template>
      <div class="form-row">
        <fc-multiple-item-dropdown
          [additionalTemplate]="additionalTemplate"
          [itemList]="groups"
          [searchKey]="'name'"
          formControlName="groups"
          title="Choose vehicle groups"
        ></fc-multiple-item-dropdown>
        <ng-template #additionalTemplate let-options>
          <div
            [style.background]="options.color"
            class="additional-group-color"
          ></div>
        </ng-template>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="align-end gap-8">
    <fc-stroked-button color="secondary" (onClick)="closeVehicleForm()"
      >Cancel
    </fc-stroked-button>
    <fc-filled-button
      (onClick)="submitForm()"
      [disabled]="isDisabled"
      [loading]="loading"
    >
      {{ vehicle ? 'Save' : 'Create' }}
    </fc-filled-button>
  </mat-dialog-actions>
</ng-container>
