import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MediaViewDialogComponent } from '@fc-shared/ui/media-view-dialog/media-view-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogClose } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MomentFormatPipeModule } from '@fc-shared/pipes/moment-format-pipe/moment-format-pipe.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MediaViewPhotoComponent } from '@fc-shared/ui/media-view-dialog/media-view-photo/media-view-photo.component';
import { AvatarPlaceholderModule } from '@fc-shared/ui/avatar-placeholder/avatar-placeholder.module';
import { ThumbnailsPipeModule } from '@fc-shared/pipes/thumbnails-pipe/thumbnails-pipe.module';
import { MediaViewPdfComponent } from './media-view-pdf/media-view-pdf.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { IconButtonComponent } from '@fc-shared/ui/buttons/icon-button.component';

@NgModule({
  declarations: [
    MediaViewDialogComponent,
    MediaViewPhotoComponent,
    MediaViewPdfComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDialogClose,
    MatMenuModule,
    MatTooltipModule,
    MomentFormatPipeModule,
    NgOptimizedImage,
    AvatarPlaceholderModule,
    ThumbnailsPipeModule,
    PdfViewerModule,
    IconComponent,
    IconButtonComponent,
  ],
  exports: [MediaViewDialogComponent],
})
export class MediaViewDialogModule {}
