import { Component, effect, Signal } from '@angular/core';
import { VehicleGroup } from '@fc-core';
import { Store } from '@ngrx/store';
import {
  getSelectedGroup,
  selectRouteParams,
  selectVehicleGroupId,
} from '../../../store';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'fc-vehicle-groups-page',
  templateUrl: './vehicle-groups-page.component.html',
  styleUrls: ['./vehicle-groups-page.component.scss'],
})
export class VehicleGroupsPageComponent {
  selectedGroup: Signal<VehicleGroup> =
    this.store.selectSignal(getSelectedGroup);
  routerParams = this.store.selectSignal(selectRouteParams);

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    effect(() => this.onRouteIdChange(), { allowSignalWrites: true });
  }

  private onRouteIdChange(): void {
    const { id } = this.routerParams();
    this.store.dispatch(selectVehicleGroupId({ id }));
  }

  editVehicleGroup(group: VehicleGroup): void {
    this.router.navigate([{ outlets: { modal: ['update-group', group.id] } }], {
      relativeTo: this.route,
    });
  }

  deleteVehicleGroup(group: VehicleGroup): void {
    this.router.navigate([{ outlets: { modal: ['delete-group', group.id] } }], {
      relativeTo: this.route,
    });
  }
}
