<div class="mobile-header">
  <fc-icon-button [routerLink]="['/vehicles/main/vehicle-groups/']">
    <fc-icon icon="icon-arrow_back"></fc-icon>
  </fc-icon-button>
  <div class="group-header" *ngIf="selectedGroup() as group">
    <div class="page-title gap-12">
      <div class="group-color" [style.background]="group.color"></div>
      <div class="group-name">{{ group.name }}</div>
    </div>
    <div class="vehicle-actions">
      <ng-container *ngTemplateOutlet="vehicleActions"></ng-container>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="vehicleActions"></ng-container>
</div>

<div class="card vehicle-group-card" *ngIf="selectedGroup() as group">
  <div class="group-header">
    <div class="page-title gap-12">
      <div class="group-color-title" [style.background]="group.color"></div>
      <div class="group-name">{{ group.name }}</div>
    </div>
    <div class="vehicle-actions">
      <ng-container *ngTemplateOutlet="vehicleActions"></ng-container>
    </div>
  </div>
  <router-outlet></router-outlet>
  <router-outlet name="modal"></router-outlet>
</div>
<ng-template #vehicleActions>
  <div class="actions" *ngIf="selectedGroup() as group">
    <fc-icon-button data-cy="vehicle-group-more-btn" [matMenuTriggerFor]="menu">
      <fc-icon icon="icon-more_vert"></fc-icon>
    </fc-icon-button>
    <mat-menu #menu="matMenu">
      <button
        (click)="editVehicleGroup(group)"
        data-cy="edit-group-btn"
        mat-menu-item
      >
        <fc-icon icon="icon-create"></fc-icon>
        <span>Edit</span>
      </button>
      <button
        (click)="deleteVehicleGroup(group)"
        data-cy="delete-group-btn"
        mat-menu-item
      >
        <fc-icon icon="icon-delete_outline"></fc-icon>
        <span>Delete</span>
      </button>
    </mat-menu>
  </div>
</ng-template>
