import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MediaModel } from '@fc-vehicles/service/media-model';
import { MediaViewService } from '@fc-shared/ui/media-view-dialog/media-view.service';
import { VehicleNotesMediaService } from '@fc-vehicles/vehicle-notes/services/vehicle-notes-media.service';

@Component({
  selector: 'fc-media-view-photo',
  template: `
    <img
      class="current-file"
      alt="file-image"
      [hidden]="!isFileLoaded"
      [src]="fileImage"
      [style.width.px]="currentPhotoSize?.width"
      [style.height.px]="currentPhotoSize?.height"
      (load)="fileLoaded.emit(true)"
    />
  `,
  styles: [
    `
      @use 'var' as *;

      :host {
        display: block;
        width: 100%;
        height: 100%;
      }

      .current-file {
        @media (max-width: $sm-size) {
          width: 100%;
        }
      }
    `,
  ],
})
export class MediaViewPhotoComponent {
  @Input('file') set setFile(file: MediaModel) {
    // if there is no original image we should set type placeholder for it
    this.fileImage = !file.originalImg
      ? this.vehicleNotesMediaService.getMediaPreview(file)
      : this.mediaViewService.getThumbnailUrl(file);
  }

  @Input() isFileLoaded = true;
  @Input() currentPhotoSize: { width: number; height: number };
  @Output() fileLoaded = new EventEmitter<boolean>();
  fileImage: string;

  constructor(
    private mediaViewService: MediaViewService,
    private vehicleNotesMediaService: VehicleNotesMediaService,
  ) {}
}
